import React,{useEffect} from 'react';
import { Col, Card,Row } from 'reactstrap';
import Header from '../header/header';
import './finalOffering.scss'
import { useSelector, useDispatch } from 'react-redux';
import { getLoanDetails } from '../../../actions/AllActions';
const FinalOffering = ({camData,loanId}) => {
    const dispatch = useDispatch();
    const loan_details = useSelector(state => state.all.loan_details);    
    useEffect(() => {       
        dispatch(getLoanDetails(loanId));
    },[]);

    return (
        <>
            <Col md="12 pb-4">
                <Card>
                    <Header headerText="Final offering" headerImg="/images/cam_version_3/calculator2.svg" />
                </Card>
            </Col>
            {camData &&
            <Col md="12 pb-4" className="finalOffering">
                <Card className="p-4 card">
                    <Row>
                        <Col sm="6" className="col-md-2-0 my-2 loanofferP">
                            <div className="text-center  p-4 offerBox" style={{backgroundColor:"#FCFBE6"}}>
                            <p className="p1">Final Loan Value</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.loan_amount ? <span>&#8377;</span>:"-"}{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.loan_amount && Number(loan_details.data.attributes.loan_amount).toLocaleString("en-IN")}</p> 
                            </div>   
                        </Col>
                        <Col sm="6" className="col-md-2-0 my-2 loanofferP">
                        <div className="text-center px-3 py-4 offerBox" style={{backgroundColor:"#EEEEFF"}}>
                            <p className="p1">Interest Rate Offered</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.interest_rate}{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.interest_rate ? <span>%</span>:"-"}</p>
                            </div>    
                        </Col>
                        <Col sm="6" className="col-md-2-0 my-2 loanofferP">
                        <div className="text-center  p-4 offerBox" style={{backgroundColor:"#FCE6EE"}}>                            
                            <p className="p1">Product Type</p>
                            <p className="p2 text-capitalize">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.product_type ? loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.product_type.replace("_"," "): "-"}</p>   
                            </div> 
                        </Col>
                        <Col sm="6" className="col-md-2-0 my-2 loanofferP">
                        <div className="text-center  p-4 offerBox"  style={{backgroundColor:"#DEF9F2"}}>
                            <p className="p1">Tenure</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.tenure}{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.tenure ?" Months": "-"} </p> 
                        </div>   
                        </Col>
                        <Col sm="6" className="col-md-2-0 my-2 loanofferP" >
                        <div className="text-center px-3 py-4 offerBox" style={{backgroundColor:"#FFF1F1"}}>
                            <p className="p1">Repayment Structure</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.product_type === 'supply_chain' ? 'Bullet' : 'EMI'}</p>
                        </div>    
                        </Col>
                         <Col sm="6" className="col-md-2-0 my-2 loanofferP" >
                        <div className="text-center px-3 py-4 offerBox" style={{backgroundColor:"#f0fdfa"}}>
                            <p className="p1">Collateral</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.cash_collateral}{loan_details && loan_details.data && Number(loan_details.data.attributes && loan_details.data.attributes.cash_collateral) >= 0 ? <span>%</span>:"-"}</p> 
                        </div>    
                        </Col>
                        <Col sm="12" className="col-md-2-0 my-2 loanofferP">
                        <div className="text-center  p-4 offerBox"  style={{backgroundColor:"#2683e91c"}}>
                            <p className="p1">Credit Period</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.credit_pariod}{loan_details && loan_details.data && loan_details.data.attributes && loan_details.data.attributes.credit_pariod ?" Days": "-"} </p> 
                        </div>
                        </Col>
                        <Col sm="12" className="col-md-2-0 my-2 loanofferP">
                        <div className="text-center p-4 offerBox" style={{backgroundColor:"#8e6e1c1f"}}>
                            <p className="p1">Processing Fees(PF)</p>
                            <p className="p2">{loan_details && loan_details.data && loan_details.data.attributes ? ( loan_details.data.attributes.processing_rate  ? loan_details.data.attributes.processing_rate + "%" : "-" ) //+ " " + ( loan_details.data.attributes.processing_fee  ? "(₹" + Number(loan_details.data.attributes.processing_fee).toLocaleString("en-IN") + ")" : "" )
                             : "-"}</p>
                        </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
            }
        </>
    )
}
export default FinalOffering;